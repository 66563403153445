import React from 'react';
import dynamic from 'next/dynamic';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import common from 'superagent-jsonapify/common';
import request from '@/utils/request';
import { metadata, applyEntityTransform } from '@/utils/transforms';
import SubRequests from '@/utils/subrequest';
import Typography from '@material-ui/core/Typography';
import parse from 'html-react-parser';
import { GlassMagnifier } from 'react-image-magnifiers';

export const componentList = {
  CustomMuiMediaSlider: dynamic(() => import(`../components/03_organisms/CarouselMuiSlider`), { ssr: false }),
  CarouselContentSlider: dynamic(() => import(`../components/03_organisms/CarouselContentSlider`), { ssr: false }),
  CustomMuiCarousel: dynamic(() => import(`../components/03_organisms/CardCarousel`), { ssr: false }),
  CustomMuiAccordion: dynamic(() => import(`../components/02_molecules/Accordion`)),
  TunnellButtonsRow: dynamic(() => import(`../components/01_atoms/ButtonsRow`)),
  TunnellBlockCTA: dynamic(() => import(`../components/02_molecules/BlockCTA/BlockCta`)),
  TunnellCoverCallout: dynamic(() => import(`../components/03_organisms/CoverCallout/CoverCallout`), { ssr: false }),
  TunnellCoverImage: dynamic(() => import(`../components/02_molecules/CoverImage/CoverImage`), { ssr: false }),
  TunnellBanner: dynamic(() => import(`../components/03_organisms/Banner`)),
  TunnellListingCard: dynamic(() => import(`../components/02_molecules/ListingCard/ListingCard`)),
  TunnellListingTeaser: dynamic(() => import(`../components/02_molecules/HomesiteTeaser/HomesiteTeaser`)),
  TunnellArticleGrid: dynamic(() => import(`../components/02_molecules/ArticleGrid`)),
  TunnellArticleFeatured: dynamic(() => import(`../components/02_molecules/ArticleTeaser/ArticleTeaser`)),
  TunnellContentGrid: dynamic(() => import(`../components/02_molecules/ContentList`)),
  TunnellSFSearchGrid: dynamic(() => import(`../components/01_atoms/ListingSearchForm/salesforce`)),
  TunnellSFSearchMap: dynamic(() => import(`../components/01_atoms/Maps/MapBlock`)),
  TunnellEscapiaSearchGrid: dynamic(() => import(`../components/01_atoms/ListingSearchForm/escapia`)),
  TunnellEscapiaAvailabilitySearch: dynamic(() => import(`../components/02_molecules/Escapia/CheckAvailabilityFormSmall`)),
  TunnellTeam: dynamic(() => import(`../components/03_organisms/TeamMemberTeaser/TeamMemberTeaser`)),
  FormCTABlock: dynamic(() => import(`../components/02_molecules/FormCTA/FormCta`), { ssr: false }),
  TestimonialCarousel: dynamic(() => import(`../components/03_organisms/TestimonialSlider/TestimonialSlider`), { ssr: false }),
  ReactRESTView: dynamic(() => import(`../components/01_atoms/ReactRESTView`)),
  EscapiaReview: dynamic(() => import(`../components/03_organisms/EscapiaReview`), { ssr: false }),
  ReactWebformEmbed: dynamic(() => import(`../components/01_atoms/WebformEmbed`), { ssr: false }),
}

export function chooseRandomList(arr, num = 1) {
  console.log('chooseRandomList = ', arr, num);

  let res = [];
  if (arr.length > num) {
    for (let i = 0; i < num;) {
      const random = Math.floor(Math.random() * arr.length);
      if (res.indexOf(arr[random]) !== -1) {
        continue;
      }
      res.push(arr[random]);
      i++;
    }
  }
  else {
    res = arr;
  }
  console.log('chooseRandomList result = ', res);
  return res;
}

export function parseJSONAPI(text) {
  var parse = common.parse;
  return parse(text);
}

export const parseGutenbergReact = (key, block, skipRestRequests) => {
  let res = null, orig_type = block?.type;
  if (block.type?.includes('rendered_html')) {
    res = (<div key={`inner-${key}`}
      dangerouslySetInnerHTML={{ __html: block.data }}></div>);
  }
  else if (block.type) {
    if (block.type == 'GutenbergCover') {
      block.type = 'GutenbergSectionWrap';
    }
    let DynamicComp = componentList[block.type];
    if (DynamicComp) {
      let props = (block.props && { ...block.props }) || {};
      if (block.props?.length > 1) {
        res = (
          <>
            <Grid spacing={3}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {block.props.map((v, i) => {
                return (
                  <Grid item key={`grid-mui-el-${i}`}>
                    <DynamicComp {...v} key={`inner-${block.type}-${key}`}
                      block={block} skipData={skipRestRequests} />
                  </Grid>
                );
              })
              }
            </Grid>
          </>
        );
      }
      else {
        props = (block.props?.length && { ...block.props[0] }) || {}
        res = (<DynamicComp {...props} skipData={skipRestRequests} block={block}
          key={`inner-${block.type}-${key}`} />);
      }
    }
  }
  return res;
};

export const parseParagraphReact = (key, block, slides, skipRestRequests) => {
  let res = null, orig_type = block?.type;
  slides = slides || [];

  if (block.type?.length) {
    let new_block = {};

    if (block.type == 'para--accordion') {
      orig_type = 'CustomMuiAccordion';
      new_block = { para: block.section, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--img_banner') {
      orig_type = 'TunnellBanner';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--video_banner') {
      orig_type = 'TunnellBanner';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--free_text') {
      return (
        <Typography key={`inner-${block.type}-${block.internalId}-${key}`} component={`div`}
          dangerouslySetInnerHTML={{ __html: block.body }}></Typography>
      );
    }
    else if (block.type == 'para--cta_card') {
      orig_type = 'TunnellBlockCTA';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--btn') {
      orig_type = 'TunnellButtonsRow';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--cover') {
      orig_type = 'TunnellCoverCallout';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--forms') {
      orig_type = 'FormCTABlock';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--team') {
      orig_type = 'TunnellTeam';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--node_slider' && slides?.length > 0) {
      let DynamicComp = componentList['CustomMuiMediaSlider'];
      let slickSett = {
        className: "center",
        centerMode: false,
        infinite: false,
        centerPadding: 0,
        speed: 500,
        rows: 2,
        // lazyLoad: 'ondemand',
        slidesToShow: 3,
        slidesToScroll: 1,
      };
      if (slides?.length <= 5) {
        DynamicComp = componentList['CarouselContentSlider'];
        slickSett['slidesPerRow'] = 1;
        slickSett['slidesToShow'] = 1;
        slickSett['centerMode'] = true;
        slickSett['arrows'] = false;
        slickSett['dots'] = true;
        delete slickSett['rows'];
        return (
            <Box key={`inner-${block.type}-${block.internalId}-${key}`} style={{ minHeight: 400 }} my={5}>
              <DynamicComp slides={slides} showLight={true} slickSett={slickSett} featured={false} />
            </Box>
        );
      }
      if (slides?.length <= 8) {
        slickSett['slidesToShow'] = 2;
      }
      return (
        <Box key={`inner-${block.type}-${block.internalId}-${key}`} style={{ minHeight: 400 }} className={`${block.type}`} my={5}>
          <DynamicComp slides={slides} showLight={true} slickSett={slickSett} featured={true} />
        </Box>
      );
    }
    else if (block.type == 'para--testimonials') {
      orig_type = 'TestimonialCarousel';
      new_block = { ...block, key: block.id.substring(block.id.length - 7) };
    }
    else if (block.type == 'para--sf_list_search') {
      orig_type = 'TunnellSFSearchGrid';
      let DynamicComp = componentList[orig_type];
      return (<Box className={`${block.type}`} my={5}>
        <DynamicComp block={{ ...block }} key={`inner-para--sf_list_search-${block.internalId}-${key}`} /></Box>
      )
    }
    else if (block.type == 'para--sf_listings_map') {
      orig_type = 'TunnellSFSearchMap';
      let DynamicComp = componentList[orig_type];
      return (<div className={`${block.type}`}>
        <DynamicComp block={{ ...block }} key={`inner-${block.type}-${block.internalId}-${key}`} /></div>
      )
    }
    else if (block.type == 'para--esc_list_search') {
      orig_type = 'TunnellEscapiaSearchGrid';
      let DynamicComp = componentList[orig_type];
      return (<div className={`${block.type}`}>
        <DynamicComp block={{ ...block }} key={`inner-${block.type}-${block.internalId}-${key}`} /></div>
      )
    }
    else if (block.type == 'para--escapia_rentals_search') {
      orig_type = 'TunnellEscapiaAvailabilitySearch';
      let DynamicComp = componentList[orig_type];
      return (<div className={`${block.type}`}>
            <DynamicComp block={{ ...block }} key={`inner-${block.type}-${block.internalId}-${key}`} /></div>
      )
    }
    else if (block.type == 'para--magnifier') {
      if (block.imageOrig) {
        return (
          <GlassMagnifier key={`inner-para--magnifier-${block.internalId}-${key}`}
            className={`magnifier`} magnifierBorderSize={5} imageAlt={block.imageAlt || 'Media'}
            imageSrc={block.imageOrig} largeImageSrc={block.image_styles?.orig_optimized?.href} />
        )
      }
      return null;
    }
    else if (block.type == 'para--content_list') {
      orig_type = 'TunnellContentGrid';
      let lq = {
        'include': 'domSrc,image,image.imageFile,category',
        'fields[articles]': 'id,internalId,path,title,created,image,body,category',
        'fields[images]': 'imageFile',
        'fields[files]': 'uri',
        'sort': '-changed',
        'filter[isPublished][value]': 1,
        'filter[domSrc.meta.drupal_internal__target_id]': block?.domSrc == '012j00000019SAWAA2' ? 'potnets' : 'baywoods',
      };
      let view_modes = { 'card': 'TunnellArticleGrid', 'teaser': 'TunnellArticleFeatured' };

      new_block = {
        key: block.id.substring(block.id.length - 7),
        perPage: block?.pager?.first || 4,
        noPager: block?.pagerYn || false,
        colMd: (block?.pager?.second && (12 / parseInt(block?.pager?.second))) || 4,
        spacing: 2,
        fetchOpts: {
          url: `/articles`,
          query: lq,
          opts: { type: 'api' },
        },
        comp: block?.viewMode ? view_modes[block.viewMode] : `TunnellArticleGrid`,
      };
      let DynamicComp = componentList[orig_type];

      if (DynamicComp) {
        return (
          <div key={`inner-${block.type}-${block.internalId}-${key}`} className={`${block.type}`} id={`${block.type}-${block.internalId}`}>
            {block?.body?.length > 0 &&
              <Typography component={`div`}
                dangerouslySetInnerHTML={{ __html: block.body }}></Typography>
            }
            <DynamicComp skipData={skipRestRequests} block={new_block}
              key={`inner-${orig_type}-${block.internalId}-${key}`} />
            {block?.ctaLinks?.length > 0 &&
              <Box display={`flex`} py={3}>
                <Grid container justifyContent={`center`} spacing={4}>
                  {block?.ctaLinks?.map((btn, index) => (
                    <Grid item key={`btn-i-${index}`}>
                      <Button disableElevation variant="contained" color="primary"
                        href={btn.second}>{parse(btn.first)}</Button>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            }
          </div>
        );
      }
    }
    else if (block.type == 'para--grid') {
      if (block?.para?.length > 0) {
        return (
          <Box key={`inner-${block.type}-${block.internalId}-${key}`} display={`flex`} py={3}>
            <Grid container justifyContent={`center`} spacing={4}>
              {block.para.map((para_ent, key) => {
                return (
                  <Grid item xs={12} md={(block?.pager?.second && (12 / parseInt(block?.pager?.second))) || 4} key={key}>
                    {parseParagraphReact(key, para_ent)}
                  </Grid>
                )
              })
              }
            </Grid>
          </Box>
        );
      }
      return null;
    }

    let DynamicComp = componentList[orig_type];

    if (DynamicComp) {
      res = (<div key={`wrapper-${orig_type}-${block.internalId}-${key}`}
        className={`${block.type}`} id={`${block.type}-${block.internalId}`}>
        <DynamicComp skipData={skipRestRequests} block={new_block}
          key={`inner-${orig_type}-${block.internalId}-${key}`} /></div>
      );
    }
  }

  return res;
};

export function getGlobalElementsLookup(path, ent_type) {
  // @see: https://www.lullabot.com/articles/incredible-decoupled-performance-with-subrequests
  const sub = new SubRequests(`${process.env.NEXT_PUBLIC_BACKEND_URL}/subrequests?_format=json`);
  const obj_path = ent_type == 'term' ? `/taxonomy/term/{{pageroute.body@$.entity.id}}` : `/{{pageroute.body@$.entity.type}}/{{pageroute.body@$.entity.id}}`;
  const menu = process.env.NEXT_PUBLIC_FRONT_URL.includes('potnets') ? `pot-nets` : `baywood-greens`;
  const menu_footer = process.env.NEXT_PUBLIC_FRONT_URL.includes('potnets') ? `pot-nets-footer` : `baywood-footer`;

  sub.add({
    requestId: "pagemenu",
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/menu/${menu}`,
  });

  sub.add({
    requestId: "footermenu",
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/menu/${menu_footer}`,
  });

  sub.add({
    requestId: "pageroute",
    uri: `/router/translate-path?_format=json&path=${path}`,
  });

  sub.add({
    requestId: "pagemeta",
    // uri:
    // `${process.env.DECOUPLED_PREFIX}/metatag?path=/{{pageroute.body@$.jsonapi.resourceName}}/${path}`,
    uri: `${process.env.NEXT_PUBLIC_DECOUPLED_PREFIX}/metatag?_format=json&path=${obj_path}`,
    waitFor: ["pageroute"],
  });

  return sub;
}

/**
 * Returns internal info about the page.
 *
 * @param path
 *   Drupal path to look up.
 *
 * @returns {Promise<any>}
 */
export const getPageInfo = path => new Promise((resolve, reject) => {
  request('tld')
    .get(`/router/translate-path?_format=json&path=${path}`)
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    .ok(resp => resp.statusCode)
    .then((response) => {
      resolve({
        page: response.statusCode === 200 ? response.body : {},
        statusCode: response.statusCode,
        not200: response.statusCode != 200 ? response.body : {},
      });
    })
    .catch((error) => {
      console.error('Could not fetch page info.', error);
      reject(error);
    });
});

/**
 * Returns internal info about the page (with authenticated account
 * permissions).
 *
 * @param url
 *   Drupal API URL to look up.
 *
 * @returns {Promise<any>}
 */
export async function getPageDataAuthenticated(url, query, opts = {}) {
  query = query || {};
  let q = {};

  if (url.includes('/users')) {
    q = {
      'include': 'image,image.imageFile,roles',
      'fields[roles]': 'label,internalId',
      'fields[images]': 'imageFile',
      // 'fields[listings]': 'internalId,path,title,price,mlsNum,saleType,saleStatus',
      'fields[files]': 'uri',
      ...query
    };
  }

  const res = await fetchAPIRequestData(url, q, opts);
  return res;
}


/**
 * Returns internal info about the page.
 *
 * @param url
 *   Drupal API URL to look up.
 *
 * @returns {Promise<any>}
 */
export async function getIndividualPageData(url, query, opts) {
  query = query || {};
  opts = opts || {};
  let q = {};

  if (url.includes('/articles')) {
    q = {
      'include': 'domSrc,image,image.imageFile,category,para,para.image.imageFile,para.video,para.viewRef',
      'fields[images]': 'imageFile',
      'fields[videosLocal]': 'videoFile',
      'fields[videosRemote]': 'videoEmbed',
      'fields[files]': 'uri',
      ...query
    };
  }
  else if (url.includes('/pages')) {
    q = {
      'include': 'domSrc,image,image.imageFile,slides,slides.imageFile,para,para.image.imageFile,para.video,para.viewRef,paraTop,paraTop.video,paraTop.image.imageFile,para.para',
      'fields[para--dynamic]': 'viewRef,bgColor,wavesStyle',
      'fields[images]': 'imageFile',
      'fields[videosLocal]': 'videoFile',
      'fields[videosRemote]': 'videoEmbed',
      'fields[files]': 'uri',
      ...query
    };
  }
  else if (url.includes('/golf')) {
    q = {
      'include': 'domSrc,image,course,image.imageFile,slides,slides.imageFile,video',
      'fields[images]': 'imageFile',
      'fields[videosLocal]': 'videoFile',
      'fields[videosRemote]': 'videoEmbed',
      'fields[files]': 'uri',
      ...query
    };
  }
  else if (url.includes('/rentals')) {
    q = {
      'include': 'domSrc,image,image.imageFile,slides,slides.imageFile,rateCalFile.docFile,houseFiles.docFile,amenities',
      'fields[imagesEscapia]': 'imageFile',
      'fields[documents]': 'docFile',
      'fields[files]': 'uri',
      ...query
    };
  }

  const res = await fetchAPIRequestData(url, q);
  return res;
}

/**
 * Returns internal info about the page.
 *
 * @param string url
 *   Drupal API URL to look up.
 * @param object query
 *   Drupal API query object.
 * @param object opts
 *   Options as an object {type: "api|tld|decoupled|solr|false"}.
 * @param integer pageLimit
 *   Query: limit of returned results.
 * @param integer pageOffset
 *   Query: page offset.
 *
 * @returns {Promise<any>}
 */
export const fetchAPIRequestData = async (url, query, opts, pageLimit, pageOffset) => new Promise((resolve, reject) => {
  const pref = opts?.type || false;
  const auth = opts?.auth || false; // "auth" should contain oAuth access token.

  // pageLimit = pageLimit || false;
  // pageOffset = pageOffset || 0;

  // Pagination and/offset should be calculated differently for REST views vs. JSON DB query:
  const db_view = opts?.dbview || false; // signals we're pulling from Drupal JSON/REST View.
  if (pageLimit && pageLimit > 0 && (!pref || !pref.includes('solr'))) {
    query = (['rest', 'json'].includes(db_view)) ? { ...query, 'items_per_page': pageLimit } : { ...query, 'page[limit]': pageLimit };
  }
  if (pageOffset && (!pref || !pref.includes('solr'))) {
    query = (['rest', 'json'].includes(db_view)) ? { ...query, 'page': pageOffset } : { ...query, 'page[offset]': pageOffset };
  }

  request(pref, false, auth)
    .get(url)
    .query(query)
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    .ok(resp => {
      if (resp.unauthorized || resp.forbidden || resp.clientError) {
        console.error(resp.error);
        reject(resp);
        // throw new Error(resp.error);
      }
      return resp.statusCode;
    })
    .then((response) => {
      // console.log('fetchAPIRequestData response', response.body, url, query, opts, pageLimit, pageOffset);
      let new_data;

      let respCount = (response.body?.meta?.count) ? parseInt(response.body.meta.count) : false;
      let respPages = (pageLimit && pageLimit > 0 && response.body?.meta?.count) ? Math.ceil(parseInt(response.body.meta.count) / parseInt(pageLimit)) : false;

        // From JSON API Media object:
      if (url.includes('imagesMls')) {
        new_data = response.body.data; //composeSliderMedia(response.body);
      }
      // From REST view:
      else if (url.includes('jsonapi/rest/view/listing-media')) {
        new_data = response.body;
      }
      else if (db_view) {
        new_data = applyEntityTransform(response.body?.results, 'listings', true);
        respCount = (response.body?.pager?.count) ? parseInt(response.body.pager.count) : false;
        respPages = (response.body?.pager?.pages) ? parseInt(response.body.pager.pages) : false;
      }
      else {
        new_data = (pref && pref.includes('solr')) ? applyEntityTransform(response.body, false, true) : applyEntityTransform(response.body);
      }

      resolve({
        page_data: response.statusCode === 200 ? new_data : {},
        statusCode: response.statusCode,
        ttlResults: respCount,
        ttlPages: respPages,
      });
    })
    .catch((error) => {
      console.error('fetchAPIRequestData. Could not fetch page data.', error);
      reject(error);
    });
});

export const fetchAPIRequestDataSWR = async (url, query, opts, pageLimit, pageOffset) => {
  const pref = opts?.type || false;
  const auth = opts?.auth || false; // "auth" should contain oAuth access token.
  pageLimit = pageLimit || false;
  pageOffset = pageOffset || 0;

  if (pageLimit && pageLimit > 0 && (!pref || !pref?.includes('solr'))) {
    query = { ...query, 'page[limit]': pageLimit };
  }
  if (pageOffset && (!pref || !pref?.includes('solr'))) {
    query = { ...query, 'page[offset]': pageOffset };
  }

  let result = await request(pref, false, auth)
    .get(url)
    .query(query)
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    .ok(resp => {
      if (resp.unauthorized || resp.forbidden || resp.clientError) {
        // console.error(resp.error);
        reject(resp);
        // throw new Error(resp.error);
      }
      return resp.statusCode;
    })
    .then(response => {
      let new_data;
      if (url.includes('imagesMls')) {
        new_data = response.body.data; //composeSliderMedia(response.body);
      }
      else {
        let results = response.body?.results || response.body;
        new_data = (pref && pref.includes('solr')) ? applyEntityTransform(results, false, true) : applyEntityTransform(results);
      }

      return {
        page_data: response.statusCode === 200 ? new_data : {},
        statusCode: response.statusCode,
        ttlResults: (response.body?.meta?.count) ? parseInt(response.body.meta.count) : false,
        ttlPages: (pageLimit && pageLimit > 0 && response.body?.meta?.count) ? Math.ceil(parseInt(response.body.meta.count) / parseInt(pageLimit)) : false,
      }
    })
    .catch((error) => {
      console.error('fetchAPIRequestDataSWR. Could not fetch page data.', error);
      return error;
    });

  return result;
}

/**
 * Returns single Menu links from Drupal backend by machine_name.
 *
 * @param id
 *   Machine name of the menu.
 *
 * @returns {Promise<any>}
 */
export const getMenuLinks = id => new Promise((resolve, reject) => {

  request('decouple')
    .get(`/menu/${id}`)
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    .ok(resp => resp.statusCode)
    .then((response) => {
      resolve({
        menu: response.statusCode === 200 ? response.body.data : {},
        statusCode: response.statusCode,
      });
    })
    .catch((error) => {
      console.error('Could not fetch the menu.', error);
      reject(error);
    });
});

/**
 * Returns metadata values from Drupal backend by path.
 *
 * @param id
 *   Path (internal or alias) to retrieve metadata for.
 *
 * @returns {Promise<any>}
 */
export const getMetatags = id => new Promise((resolve, reject) => {
  request('decouple')
    .get(`/metatag?path=/${id}`)
    // Tell superagent to consider any valid Drupal response as successful.
    // Later we can capture error codes if needed.
    .ok(resp => resp.statusCode)
    .then((response) => {
      resolve({
        metatags: response.statusCode === 200 ? metadata(response.body.data) : [],
      });
    })
    .catch((error) => {
      console.error('Could not fetch the meta data.', error);
      reject(error);
    });
});
