import {useEffect, useRef, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import Logo from '@/components/01_atoms/Logo/Logo';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import {makeStyles, createStyles, withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Toolbar from '@material-ui/core/Toolbar';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import SocialAccountsPotnets from '@/components/01_atoms/SocialAccounts/SocialAccountsPotnets'

const useStyles = makeStyles((theme) => createStyles({
  root: {
    // display: 'block',
    zIndex: 101,
    position: 'relative',
  },
  textCenter: {
    textAlign: 'center',
  },
  topBarStyles: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& .socialAccountsWrapper': {
      '& a': {
        marginBottom: 0,
        marginTop: 0,
        '& svg': {
          color: '#02606E',
        }
      }
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: 'transparent !important',
    minHeight: 150,
    zIndex: 100,
    "& p": {
      color: theme.palette.grey[700],
    },
    display: 'inline-block',
    marginBottom: 15,
    // marginBottom: '-150px',
  },

  utilityMenu: {
    display: 'flex',
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      justifyContent: 'center',
    },
    '& li:last-child': {
      [theme.breakpoints.down('sm')]: {
        borderRight: 0
      },
    },
    "& li ": {
      paddingTop: 0,
      paddingBottom: 0,
      width: 'auto',
      borderRight: '1px solid #006680',
    },
    "& a ": {
      display: 'flex',
      alignItems: 'inherit',
      fontWeight: 600,
      lineHeight: 2,


      [theme.breakpoints.down("xs")]: {
        fontSize: '.7rem',
        padding: '0 .5rem',
      },

      '&:hover': {
        color: theme.palette.primary,
      },


    },
    "& li:last-child a": {
      paddingRight: 0,
    },
    "& svg ": {
      width: 24,
      height: 24,
      margin: '0 .5rem 0 0',
      [theme.breakpoints.down("sm")]: {
        width: 16,
        margin: '0 .25rem 0 0',

      },
    },
  },
  "& .residentLogin svg": {
    width: 18,
    height: 18,
  },
  mainMenuAccordions: {
    display: 'flex',
    background: 'transparent',
    border: 0,
    justifyContent: 'center',

    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      justifyContent: 'start',

    },
    "& .MuiAccordionSummary-root:hover a, & .MuiAccordionSummary-root:hover span": {
      color: theme.palette.primary.main,
    },
    "& .MuiAccordion-root": {
      position: 'relative',
      margin: '0 !important',
      border: 'none',
      "& li": {
        padding: 0,
        width: '100%',
        display: 'block',
      },

      "&  span, & a": {
        display: 'block',
        color: '#006680',
        fontSize: 22,
        fontWeight: 700,
        padding: '0 1rem',
        textTransform: 'uppercase',
        [theme.breakpoints.down('md')]: {
          fontSize: 18,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: 20,
        },

      },
      "& svg": {
        [theme.breakpoints.down('md')]: {
          width: '1rem',
        },
      },
      '& svg path': {
        fill: '#02606E',
        opacity: 1
      },
      "& .Mui-expanded span": {

        color: theme.palette.primary.main,
      },
      "& a": {
        padding: '.5rem 1rem',
      },
      "& ul a": {
        textTransform: 'none',
      },
      "& li li a": {
        fontWeight: 400,
      },
    },
    "& .MuiAccordionDetails-root": {
      background: 'white',
      border: 0,
      [theme.breakpoints.up("md")]: {
        boxShadow: '0px 9px 9px rgb(0 0 0 / 5%)',

        position: 'absolute',
        minWidth: 300,
        left: '1rem',
        top: '4.4rem',
        padding: '0',
      },
      "& ul": {
        width: '100%',
      },
      "& li li a": {
        paddingLeft: '1.25rem !important',
      },
      "& a": {
        display: 'block',
        padding: '.2rem 0',
        fontSize: '18px !important',
      },
      "& a:hover": {
        backgroundColor: theme.palette.grey[200],
        textDecoration: 'none',
      }
    },
    "& svg": {
      transform: 'rotate(0deg)',
    },
    "& .Mui-expanded svg": {
      transform: 'rotate(180deg)',
    },

  },
  ctaContainer: {
    textAlign: 'right',
  },
  headerCta: {
    marginLeft: 'auto',
    fontSize: '16px !important',
    padding: '0.9rem 1.125rem .5rem !important',
    [theme.breakpoints.up("lg")]: {
      fontSize: '1.25rem !important',
    },
  },

  headerCtaDesktop: {
    fontSize: '.8rem',
    padding: '.7rem 1rem .5rem',
    [theme.breakpoints.up("lg")]: {
      fontSize: '1.25rem !important',
      padding: '0.9rem 1.125rem .5rem !important',
    },
  },
  mainMenuItem: {
    padding: '0 !important',
    "& span": {
      display: "block",
      marginBottom: 0,
    }
  },
  hideOnMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoContainer: {
    '& img': {
      maxWidth: 300,
      width: 300,
      paddingBottom: 0,
      [theme.breakpoints.down('md')]: {
        maxWidth: 250,
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: 210,
      },
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
  },
  iconStyles: {
    '& svg': {
      height: '2em',
      width: '2em'
    }
  },
  popoverStyles: {
    transform: 'translate3d(0px, 165px, 0px) !important',
    width: '100%',
    backgroundColor: 'rgba(255,255,255,.85)',
    zIndex: '1000',
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


export default function TemporaryDrawer(props) {
  const {menu} = props;
  const classes = useStyles();
  const logo = '/assets/pot-nets.svg';

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const main_menu = (
    <>
      {menu && menu['#items'] && (
        <Box className={classes.mainMenuAccordions}>
          {
            Object.entries(menu['#items']).map(([key, item]) => {
              let inner_lvl1 = [], inner_lvl2 = [], uniqueID1;
              let uniqueID = key.substring(key.length - 7);

              inner_lvl2 = [];
              if (item.below && (item.below.length > 0 || Object.keys(item.below).length)) {
                for (const [key1, item1] of Object.entries(item.below)) {
                  let parent = null;
                  uniqueID1 = key1.substring(key1.length - 7);
                  if (key1.includes('menu_link_content:')) {
                    parent = {
                      title: item1.title,
                      href: item1.url,
                      key: uniqueID1,
                    };

                    if (item1.below && (item1.below.length > 0 || Object.keys(item1.below).length)) {
                      for (const [key2, item2] of Object.entries(item1.below)) {
                        if (key2.includes('menu_link_content:')) {
                          let uniqueID2 = key1.substring(key2.length - 7);
                          inner_lvl2.push({
                            title: item2.title,
                            href: item2.url,
                            key: uniqueID2,
                          });
                        }
                      }
                      parent['below'] = inner_lvl2;
                    }
                    inner_lvl1.push(parent);
                    inner_lvl2 = [];
                  }
                }
              }

              return (
                <Box displayPrint="none" >
                <Accordion expanded={expanded === `nav-${uniqueID}`}
                           key={`acc-${uniqueID}`}
                           onChange={handleChange(`nav-${uniqueID}`)}>
                  <AccordionSummary aria-controls={`${uniqueID}-content`}
                                    id={`${uniqueID}-header`}>
                    <span>{item.title}</span>
                    <svg width="22" height="14" viewBox="0 0 22 14"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.5" fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.56667 0.26683L1.31174e-07 2.8335L11 13.8335L22 2.8335L19.4333 0.26683L11 8.70016L2.56667 0.26683Z"
                            fill="#C4C4C4"/>
                    </svg>
                  </AccordionSummary>
                  <AccordionDetails>
                    {inner_lvl1.length > 0 &&
                    <List>
                      {inner_lvl1.map((item11, key11) => {
                        return (
                          <ListItem key={key11}>
                            <Link href={item11.href}
                                  title={item11.title}>{item11.title}</Link>
                            {(item11['below'])?.length > 0 &&
                            <List>
                              {
                                item11['below'].map((item12, key12) => {
                                  return (
                                    <ListItem key={key12}>
                                      <Link href={item12.href}
                                            title={item12.title}>{item12.title}</Link>
                                    </ListItem>
                                  )
                                })
                              }
                            </List>
                            }
                          </ListItem>
                        )
                      })
                      }
                    </List>
                    }
                  </AccordionDetails>
                </Accordion>
                </Box>
              );
              inner_lvl1 = [];
            })
          }
        </Box>
      )}
    </>
  );

  return (
    <Box bgcolor='rgba(255,255,255,.85)' position='relative' zIndex='100'>
      <Container>
        <div className={classes.root}>
          <CssBaseline/>
          <AppBar elevation={0} position="relative" className={clsx(classes.appBar)}>
            <Grid item xs={12} className={classes.topBarStyles}>
              <List className={classes.utilityMenu}>
                <ListItem key={`utility1`}>
                  <Link href='https://jobs.teamengine.io/potnets' target={`_blank`}>
                    <span>CAREERS</span></Link>
                </ListItem>
                <ListItem key={`utility2`}>
                  <Link href='tel:8885346637'>
                    <span>Home Sales: (888) 534-6637</span></Link>
                </ListItem>
                <ListItem key={`utility3`}>
                  <Link href='tel:3029459300'><span>Main Office: (302) 945-9300</span></Link>
                </ListItem>
              </List>
              <Hidden smDown>
                <SocialAccountsPotnets/>
              </Hidden>
            </Grid>

            <Toolbar disableGutters className={classes.logoContainer}>
                <Logo width={300} height={69} alt="Potnets logo" name={logo}></Logo>
              <Hidden smDown>
                {main_menu}
                <Box  displayPrint="none">
                <Hidden smUp>
                  <Button className={classes.headerCta} size="large" href="/contact-us"
                          disableElevation variant="contained" color="primary">
                    Contact Our Sales Team
                  </Button>
                </Hidden>
                <Hidden smDown>
                  <Button className={classes.headerCtaDesktop} size="large" href="/contact-us"
                          disableElevation variant="contained" color="primary">
                    Contact Our Sales Team
                  </Button>
                </Hidden>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Box displayPrint="none">
                <IconButton
                  className={classes.iconStyles}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}>
                  <MenuIcon/>
                </IconButton>
                </Box>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined}
                        className={classes.popoverStyles}>
                  {main_menu}
                  <Container>
                    <Box align="center" my={2} ml={1}>
                    <Button size="md" href="/contact-us"
                            disableElevation variant="contained" color="primary">
                      Contact Our Sales Team
                    </Button>
                    </Box>
                  </Container>
                </Popper>
              </Hidden>
            </Toolbar>
          </AppBar>
        </div>
      </Container>
    </Box>
  );
}
